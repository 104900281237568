import _ from "lodash";
import Vue from "vue";
import m from "moment";

import { extend } from "vee-validate";
import {
    required,
    numeric,
    email,
    confirmed,
    regex,
    min,
    alpha_spaces,
} from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });
extend("numeric", { ...numeric, message: "Only numbers are allowed" });
extend("future_date", {
    validate: (value) => {
        const inputDate = new Date(value);
        const todayDate = new Date();
        if (inputDate <= todayDate) {
            return "Given date should be in the future!";
        }
        return true;
    },
});

import { mapGetters, mapActions } from "vuex";
import Select from "@/components/reusable/Fields/Select.vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import RadioButton from "@/components/reusable/Fields/RadioButton";
import TextAreaInput from "@/components/reusable/Fields/TextareaInput";
import DatePicker from "@/components/reusable/Fields/DatePicker";
import CheckBox from "@/components/reusable/Fields/CheckBox";
import Modal from "@/components/reusable/Modal";

export default {
    components: {
        Select,
        TextInput,
        RadioButton,
        TextAreaInput,
        Modal,
        DatePicker,
        CheckBox
    },
    data() {
        return {
            courseDetail: {},
            courseModules: [{ module_title: null }],
            courseOptions: [{ option_name: null }],
            semesters: [{ semester_no: null }],
            payload: {
                courseDetail: {},
                courseModules: [{ module_title: null }],
                courseOptions: [{ option_name: null }],
                semesters: [{ semester_no: null }],
            },
            templateData: null
        };
    },
    computed: {
        ...mapGetters([
            "getCourseTestTypes",
            "locationLanguageReOrder",
            "courseDeliveryMode",
            "courseHealthcareSector",
            "getCurrency",
            "getOrgIDFromAccessToken",
            "trainingTypes",
            "getOrgDepartments",
            "getOrgTypeIDFromAccessToken",
            "candidateType",
            "getLanguageExam",
            "locationCountryInfo",
            "qualificationByCountryAndCandidateType",
            "getCourseQualClassification",
            "orgDetailFromAccessToken",
            "getCourseSpeciality",
            "organisationCountryIds",
            "getISCEDLevels",
        ]),
        courseTypes() {
            return (
                this.getCourseTestTypes.map((v) => ({
                    code: v?.course_test_type_id,
                    label: v?.course_test_type,
                })) || []
            );
        },
        isEducationalInst() {
            return this.getOrgTypeIDFromAccessToken === 18
        },
        getLanguageTest() {
            return this.getLanguageExam
                .map((val) => ({
                    code: val?.test_id,
                    label:
                        val?.test_acronym && val?.test_name
                            ? `${val.test_acronym}-${val.test_name}`
                            : val?.test_name,
                    test_acronym: val?.test_acronym,
                }))
        },
        customCandidateType() {
            const nonMedical =6
            return this.candidateType.filter(v=> v.code !=nonMedical) || []
        }
    },
    methods: {
        addNewModule() {
            this.courseModules.push({});
            this.payload.courseModules.push({})
        },
        addNewOption() {
            this.courseOptions.push({});
            this.payload.courseOptions.push({})
        },
        decodeName(_name) {
            const nameArr = _name.split("__");
            const name = nameArr[0]
            const index = nameArr[1]
            return { name, index }
        },
        addNewSemester() {
            this.semesters.push({});
            this.payload.semesters.push({})
            return
        }
    }
}