<template>
  <div class="course-template">
    <div class="d-flex justify-content-between">
      <div class="mt-2">
        <h6 class="text-primary">Course Templates</h6>
      </div>
      <div class="d-flex justify-content-end">
        <CButton
          class="btn-primary small mr-2"
          style="height: 28px"
          v-c-tooltip="'Click to Add Template.!'"
          @click="openAddTemp"
          >Add</CButton
        >
      </div>
    </div>

    <p v-if="payload?.length" class="text-center">
      {{ payload.length }} Template Section{{ payload.length>1?'s':'' }} Available
    </p>

    <p v-else class="text-center text-muted"> No Template Found</p>

    <CModal
      v-if="isShowPopup"
      :show.sync="isShowPopup"
      color="primary"
      size="xl"
      :closeOnBackdrop="false"
    >
      <template #header>
        <h6>Add Course Template</h6>
        <button aria-label="Close" class="close" @click="closeModal">x</button>
      </template>
      <AddTemplate
        @onSubmit="saveTemplate"
        @cancelCallBack="closeModal"
        :templateInfo="payload"
      />
      <template #footer-wrapper>
        <div></div>
      </template>
    </CModal>
  </div>
</template>
<script>
import AddTemplate from "@/containers/CourseTemplate/AddTemplate.vue";
export default {
  name: "CourseTemplate",
  data() {
    return {
      isShowPopup: false,
      payload: null,
    };
  },
  components: {
    AddTemplate,
  },
  methods: {
    saveTemplate(data) {
      this.payload = data?.map((v) => {
        const fields = Object.keys(v.template_fields);
        const template_fields = fields.map((field) => ({
          field_name: field,
          field_value: v.template_fields[field],
        }));
        return {
          ...v,
          template_fields,
        };
      });
      this.isShowPopup = false;
      this.$emit("saveTemplate", data);
    },
    openAddTemp() {
      this.isShowPopup = true;
    },
    closeModal() {
      this.isShowPopup = false;
    },
  },
};
</script>
