<template>
  <div class="course-details">
    <h6 class="text-primary">Course Details</h6>
    <CRow>
      <CCol lg="4">
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12 required">Course Type</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <Select
                name="course_test_type_id"
                :value="courseDetail.course_test_type_id"
                :options="
                  options && options['course_type']
                    ? options['course_type']
                    : []
                "
                :multiple="false"
                :clearable="false"
                @input="handleChangeSelect"
                :error="errors[0]"
              />
            </ValidationProvider>
          </div>
        </CRow>
      </CCol>
      <CCol lg="4">
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12 required">Healthcare Sector</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <Select
                name="healthcare_sector"
                :value="courseDetail.healthcare_sector"
                :options="
                  options && options['courseHealthcareSector']
                    ? options['courseHealthcareSector']
                    : []
                "
                :multiple="false"
                :clearable="false"
                @input="handleChangeSelect"
                :error="errors[0]"
              />
            </ValidationProvider>
          </div>
        </CRow>
      </CCol>
      <CCol lg="4">
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12">Reference</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <TextInput
              name="course_ref"
              :value="courseDetail.course_ref"
              @input="handleInput"
            />
          </div>
        </CRow>
      </CCol>
      <CCol lg="4">
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12 required">Title</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <TextInput
              name="course_title"
              :value="courseDetail.course_title"
              @input="handleInput"
            />
          </div>
        </CRow>
      </CCol>
      <CCol lg="4">
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12 required">Language</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <Select
                name="language_id"
                :value="courseDetail.language_id"
                :options="
                  options && options['languages'] ? options['languages'] : []
                "
                :multiple="false"
                :clearable="false"
                @input="handleChangeSelect"
                :error="errors[0]"
              />
            </ValidationProvider>
          </div>
        </CRow>
      </CCol>
      <CCol md="4">
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12">Exam/Test Name</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <Select
              name="test_id"
              :value="courseDetail.test_id"
              @change="handleChangeSelect"
              :options="(options && options['languageExam']) || []"
              :clearable="true"
            />
          </div>
        </CRow>
      </CCol>
      <CCol md="4">
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12">CEFR Level</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <TextInput
              name="cefr_level"
              :value="courseDetail.cefr_level"
              @input="handleInput"
            />
          </div>
        </CRow>
      </CCol>
      <CCol md="8">
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12">Course Synopsis</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <TextAreaInput
              name="course_synopsis"
              :value="courseDetail.course_synopsis"
              @input="handleInput"
              :rows="3"
            />
          </div>
        </CRow>
      </CCol>
      <CCol md="4">
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12">Learning Hours</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <ValidationProvider rules="numeric" v-slot="{ errors }">
              <TextInput
                name="learning_hours"
                :value="courseDetail.learning_hours"
                @input="handleInput"
                type="number"
                :error="errors[0]"
              />
            </ValidationProvider>
          </div>
        </CRow>
      </CCol>
      <CCol md="4">
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12">Duration Access(Months)</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <ValidationProvider rules="numeric" v-slot="{ errors }">
              <TextInput
                name="duration_months"
                :value="courseDetail.duration_months"
                @input="handleInput"
                type="number"
                :error="errors[0]"
              />
            </ValidationProvider>
          </div>
        </CRow>
      </CCol>
      <CCol md="4">
        <CRow class="mb-3">
          <label class="col-lg-12 col-md-12">CPD Points</label>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <ValidationProvider rules="numeric" v-slot="{ errors }">
              <TextInput
                name="cpd_points"
                :value="courseDetail.cpd_points"
                @input="handleInput"
                type="number"
                :error="errors[0]"
              />
            </ValidationProvider>
          </div>
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import Vue from "vue";

import courseMixin from "@/containers/Course/AddCourse/courseMixin.js";
import { mapGetters } from "vuex";

export default {
  name: "CourseDetail",
  mixins: [courseMixin],
  data() {
    return {};
  },
  computed: {
    ...mapGetters([]),
    options() {
      return {
        course_type: this.courseTypes,
        languages: this.locationLanguageReOrder,
        courseHealthcareSector: this.courseHealthcareSector,
        languageExam: this.getLanguageTest
      };
    },
  },
  methods: {
    handleChangeSelect(name, value) {
      Vue.set(this.courseDetail, name, value);
      this.payload.courseDetail = {
        ...this.payload.courseDetail,
        [name]: value ? value.id || value.code || value : null,
      };
      this.updateParentData();
    },
    handleInput(name, value) {
      Vue.set(this.courseDetail, name, value);
      Vue.set(this.payload.courseDetail, name, value);
      this.updateParentData();
    },
    updateParentData() {
      const formName = "courseDetail";
      this.$emit("syncData", formName, this.payload[formName]);
    },
  },
};
</script>
