<template>
  <div class="add-course container-md col-lg-10 mb-4">
    <h4 class="text-primary main-title">Create Course</h4>
    <ValidationObserver ref="addCourse" v-slot="{ handleSubmit }">
      <form id="addCourse" @submit.prevent="handleSubmit(onSubmit)">
        <CCard class="mt-2">
          <CCardBody>
            <EduCourseDetails v-if="isEducationalInst" @syncData="syncData" />
            <CourseDetails v-else @syncData="syncData" />
          </CCardBody>
        </CCard>
        <CCard
          class="mt-2"
          v-if="isEducationalInst && educationSections['semesters_terms']"
        >
          <CCardBody>
            <SemesterDetails @syncData="syncData" />
          </CCardBody>
        </CCard>
        <CCard
          class="mt-2"
          v-if="!isEducationalInst || educationSections['options']"
        >
          <CCardBody>
            <CourseOption @syncData="syncData" />
          </CCardBody>
        </CCard>
        <CCard
          class="mt-2"
          v-if="!isEducationalInst || educationSections['modules']"
        >
          <CCardBody>
            <CourseModule @syncData="syncData" />
          </CCardBody>
        </CCard>
        <CCard class="mt-2" v-if="!isEducationalInst">
          <CCardBody>
            <CourseTemplate @saveTemplate="saveTemplate" />
          </CCardBody>
        </CCard>
        <CRow class="justify-content-center">
          <CButton
            class="mx-3 btn-submit"
            color="primary"
            variant="outline"
            type="button"
            @click="navToCourseList"
            >Cancel</CButton
          >
          <CButton
            class="mx-3 btn-submit"
            color="primary"
            type="button"
            @click="onSubmit"
            >Submit</CButton
          >
        </CRow>
      </form>
    </ValidationObserver>
    <Modal
      v-if="confirmModal.isShowPopup"
      :modalTitle="confirmModal.modalTitle"
      :modalColor="confirmModal.modalColor"
      :modalContent="confirmModal.modalContent"
      :isShowPopup="confirmModal.isShowPopup"
      :buttons="confirmModal.buttons"
      :modalCallBack="confirmModalCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>
<script>
import CourseDetails from "@/containers/Course/AddCourse/CourseDetails";
import CourseOption from "@/containers/Course/AddCourse/CourseOption";
import CourseModule from "@/containers/Course/AddCourse/CourseModule";
import { mapActions } from "vuex";
import courseMixin from "@/containers/Course/AddCourse/courseMixin.js";
import CourseTemplate from "@/containers/Course/AddCourse/CourseTemplate";
import Modal from "@/components/reusable/Modal";
import EduCourseDetails from "@/containers/Course/AddCourse/EduCourseDetails";
import SemesterDetails from "@/containers/Course/AddCourse/SemesterDetails";

export default {
  name: "AddCourse",
  mixins: [courseMixin],
  components: {
    CourseDetails,
    CourseOption,
    CourseModule,
    CourseTemplate,
    EduCourseDetails,
    SemesterDetails,
  },
  data() {
    return {
      confirmModal: {
        modalColor: "danger",
        modalTitle: "CONFIRMATION",
        modalContent: "Do you want to create course without Template?",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
        data: null,
      },
      educationSections: {
        semesters_terms: false,
        modules: false,
        options: false,
      },
    };
  },
  methods: {
    ...mapActions([
      "initCourseOptions",
      "showToast",
      "createCourseModOpt",
      "createCourseTemplate",
    ]),
    syncData(name, value) {
      if (name === "courseDetail" && this.isEducationalInst) {
        this.educationSections.semesters_terms =
          value["semesters_terms"] || false;
        this.educationSections.modules = value["modules"] || false;
        this.educationSections.options = value["options"] || false;
      }
      this.payload[name] = value;
    },
    navToCourseList() {
      this.$router.push("/list-course");
    },
    async onSubmit() {
      const isValid = await this.$refs.addCourse.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please fill mandatory fields!",
        });
        return;
      }
      let payload = {
        training_course: {
          ...this.payload.courseDetail,
          organisation_id: this.getOrgIDFromAccessToken,
          course_provider: this.orgDetailFromAccessToken?.org_name || null,
        },
      };
      if (this.isEducationalInst) {
        const training_course_modules = this.educationSections.modules
          ? this.payload.courseModules?.filter((v) => v.module_no)
          : [];
        const training_course_options = this.educationSections.options
          ? this.payload.courseOptions?.filter((v) => v.option_name)
          : [];
        const course_semesters = this.educationSections.semesters_terms
          ? this.payload.semesters
          : [];

        payload = {
          ...payload,
          course_semesters,
          training_course_options,
          training_course_modules,
        };
      } else {
        payload = {
          ...payload,
          training_course_options: [...this.payload.courseOptions],
          training_course_modules: [...this.payload.courseModules],
          course_semesters: [],
        };
      }
      if (!this.templateData?.length && !this.isEducationalInst) {
        this.confirmModal.data = payload;
        this.confirmModal.isShowPopup = true;
      } else {
        this.createCourse(payload);
      }
    },
    createCourse(payload) {
      this.createCourseModOpt(payload).then((res) => {
        if (res?.status === 200) {
          const course_id = res?.data?.training_course?.training_course_id;
          if (this.templateData?.length)
            this.createTemplate(this.templateData, course_id);
          else this.navToListView();
        }
      });
    },
    createTemplate(payload, training_course_id) {
      this.createCourseTemplate({
        training_course_id,
        payload,
      }).then((res) => {
        if (res.status == 200) {
          this.navToListView();
        }
      });
    },
    navToListView() {
      this.$router.push("/list-course");
    },
    saveTemplate(data) {
      this.templateData = data;
    },
    openConfirmModal(data) {
      this.deleteConfirmModal.isShowPopup = true;
      this.deleteConfirmModal.data = data;
    },
    confirmModalCallBack(action) {
      if (action === "Confirm") {
        const { data } = this.confirmModal;
        this.createCourse(data);
      }
      this.confirmModal.isShowPopup = false;
      this.confirmModal.data = null;
    },
  },
  mounted() {
    this.initCourseOptions();
  },
};
</script>
<style lang="scss" scoped>
.btn-submit {
  min-width: 150px;
}
</style>
