<template>
  <div class="course-option">
    <div class="d-flex justify-content-between">
      <h6 class="text-primary">Course Options</h6>
      <CButton
        class="btn-primary small mr-2"
        style="height: 28px"
        v-c-tooltip="'Click to Add new option!'"
        @click="addNewOption"
        >Add</CButton
      >
    </div>
    <div
      class="mt-2 border-dotted-bottom d-flex justify-content-between"
      v-for="(courseOption, index) in courseOptions"
      :key="index"
    >
      <div class="option-record">
        <CRow>
          <CCol md="4">
            <CRow class="mb-3">
              <label class="col-lg-12 col-md-12 required">Option</label>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <TextInput
                    name="option_name"
                    :value="courseOption.option_name"
                    @input="
                      (name, value) => handleInputMulti(name, value, index)
                    "
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </CRow>
          </CCol>
          <CCol md="4">
            <CRow class="mb-3">
              <label class="col-lg-12 col-md-12">Delivery Mode</label>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <Select
                  name="delivery_mode"
                  :value="courseOption.delivery_mode"
                  :options="
                    options && options['courseDeliveryMode']
                      ? options['courseDeliveryMode']
                      : []
                  "
                  :multiple="false"
                  :clearable="false"
                  @input="
                    (name, value) => handleChangeSelectMulti(name, value, index)
                  "
                />
              </div>
            </CRow>
          </CCol>
          <CCol md="4">
            <CRow class="mb-3">
              <label class="col-lg-12 col-md-12">Lessons</label>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <TextInput
                  name="lessons"
                  :value="courseOption.lessons"
                  @input="(name, value) => handleInputMulti(name, value, index)"
                />
              </div>
            </CRow>
          </CCol>
        </CRow>
        <CRow>
          <CCol md="4">
            <CRow class="mb-3">
              <label class="col-lg-12 col-md-12">Retail Price</label>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <TextInput
                  name="retail_price"
                  :value="courseOption.retail_price"
                  @input="(name, value) => handleInputMulti(name, value, index)"
                  type="Number"
                />
              </div>
            </CRow>
          </CCol>
          <CCol md="4">
            <CRow class="mb-3">
              <label class="col-lg-12 col-md-12">Partner Price</label>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <TextInput
                  name="partner_price"
                  :value="courseOption.partner_price"
                  @input="(name, value) => handleInputMulti(name, value, index)"
                  type="Number"
                />
              </div>
            </CRow>
          </CCol>
          <CCol md="4">
            <CRow class="mb-3">
              <label class="col-lg-12 col-md-12">Currency</label>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <Select
                  name="currency_id"
                  :value="courseOption.currency_id"
                  @change="
                    (name, value) => handleChangeSelectMulti(name, value, index)
                  "
                  :options="
                    options && options['currency'] ? options['currency'] : []
                  "
                  :taggable="false"
                  :clearable="false"
                />
              </div>
            </CRow>
          </CCol>
        </CRow>
        <CRow>
          <CCol md="4">
            <CRow md="3">
              <label class="col-lg-12 col-md-12 required">Training Type</label>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <Select
                    name="training_type_id"
                    :value="courseOption.training_type_id"
                    @change="
                      (name, value) => handleChangeSelectMulti(name, value, index)
                    "
                    :options="
                      options && options['trainingTypes'] ? options['trainingTypes'] : []
                    "
                    :taggable="false"
                    :clearable="false"
                    :error="errors[0]"
                  />
              </ValidationProvider>
              </div>
            </CRow>
          </CCol>
          <CCol md="4">
            <CRow class="mb-3">
              <label class="col-lg-12 col-md-12">Practice Test Inc.</label>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <RadioButton
                  name="practice_test_inc"
                  :value="courseOption.practice_test_inc"
                  :options="
                    options && options['boolean'] ? options['boolean'] : []
                  "
                  @change="
                    (name, value) => handleRadioButtonMulti(name, value, index)
                  "
                />
              </div>
            </CRow>
          </CCol>
        </CRow>
      </div>
      <div>
        <CButton
          class="icon mr-1"
          v-if="index > 0"
          @click="deleteOption(index)"
          v-c-tooltip="'Click to Delete this option!'"
        >
          <i class="fas fa-trash-alt" />
        </CButton>
      </div>
    </div>
    <Modal
      v-if="deleteConfirmModal.isShowPopup"
      :modalTitle="deleteConfirmModal.modalTitle"
      :modalColor="deleteConfirmModal.modalColor"
      :modalContent="deleteConfirmModal.modalContent"
      :isShowPopup="deleteConfirmModal.isShowPopup"
      :buttons="deleteConfirmModal.buttons"
      :modalCallBack="deleteModalCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>
<script>
import Vue from "vue";
import courseMixin from "@/containers/Course/AddCourse/courseMixin.js";

export default {
  name: "CourseOption",
  mixins: [courseMixin],

  data() {
    return {
      deleteConfirmModal: {
        modalColor: "danger",
        modalTitle: "NOTIFICATION",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
        data: null,
      },
    };
  },
  computed: {
    options() {
      return {
        boolean: [
          { code: true, label: "Yes" },
          { code: false, label: "No" },
        ],
        courseDeliveryMode: this.courseDeliveryMode,
        currency: this.getCurrency,
        trainingTypes: this.trainingTypes
      };
    },
  },
  methods: {
    handleInputMulti(name, value, index) {
      // const { name, index } = this.decodeName(_name);
      Vue.set(this.courseOptions[index], name, value);
      this.payload.courseOptions[index][name] = value;
      this.updateParentData();
    },
    handleChangeSelectMulti(name, value, index) {
      // const { name, index } = this.decodeName(_name);
      Vue.set(this.courseOptions[index], name, value);
      this.payload.courseOptions[index] = {
        ...this.payload.courseOptions[index],
        [name]: value?.code || value?.id || value,
      };
      this.updateParentData();
    },
    handleRadioButtonMulti(name, value, index) {
      // const { name, index } = this.decodeName(_name);
      let val = null;
      if (value === null) {
        val = null;
      } else if (value.id === false || value.code === false) {
        val = false;
      } else if (value.id === null || value.code === null) {
        val = null;
      } else {
        val = value.id || value.code || value;
      }
      Vue.set(this.courseOptions[index], name, val);
      this.payload.courseOptions[index] = {
        ...this.payload.courseOptions[index],
        [name]: val,
      };
      this.updateParentData();
    },

    deleteOption(index) {
      this.deleteConfirmModal.modalTitle = "NOTIFICATION";
      this.deleteConfirmModal.modalContent =
        "Are you sure you want to delete this Option?";
      this.deleteConfirmModal.isShowPopup = true;
      this.deleteConfirmModal.data = index;
    },
    deleteModalCallBack(action) {
      if (action === "Confirm") {
        const { data } = this.deleteConfirmModal;
        this.courseOptions.splice(data, 1);
      }
      this.deleteConfirmModal.isShowPopup = false;
      this.deleteConfirmModal.data = null;
    },
    updateParentData() {
      const formName = "courseOptions";
      this.$emit("syncData", formName, this.payload[formName]);
    },
  },
};
</script>
<style lang="scss" scoped>
.option-record {
  min-width: 95%;
}
</style>
